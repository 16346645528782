/* eslint-disable react/display-name */
import React, { useState } from "react"
import { Backdrop } from "@mui/material"
import ExfluencyLoader from "src/components/ExfluencyLoader"

export interface ExfluencyLoaderProps {
    showLoader: (message: string) => void;
    closeLoader: () => void;
}
const withExfluencyLoader = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: any) => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")

    const showLoader = (message: string) => {
      setMessage(message)
      setOpen(true)
    }

    const closeLoader = () => {
      setOpen(false)
    }

    return (<>
      <WrappedComponent {...props as P} showLoader={showLoader} closeLoader={closeLoader} />

      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <ExfluencyLoader message={message} />
      </Backdrop>
    </>)
  }
}

export default withExfluencyLoader
