interface I18nError {
    getI18nMsg(): string;
}

/**
 * Request Error class
 * 
 * Error class definition for handling HTTP request errors.
 * By default it returns http status code and text description,
 * but it can return a specific i18n template based on the return code status.
 */
export class RequestError extends Error implements I18nError {

  public status: number
  public path: string
  public method: string
  public response: any
  private request: any

  constructor(requestResponse: any) {
    super(`[${requestResponse.status}] ${requestResponse.statusText}:\n ${requestResponse.data}`)

    this.status = requestResponse.status
    this.response = requestResponse.data
    this.path = requestResponse.config.url
    this.method = requestResponse.config.method
    this.request = requestResponse.config
  }

  logRequestConfig(): void {
    console.log("%cRequest configuration:", "background: #88BAD8;color: #0d1215")
    console.log(this.request)
  }

  getI18nCode(): string {
    return `error.request.${this.status}`
  }

  getI18nMsg(): string {
    return "error.request.index"
  }
}
/**
 * No Connection Error
 * 
 * Error to handle the situation where there is No Internet access.
 * It can return an i18n message.
 */
export class NoConnectionError extends Error implements I18nError {
  constructor() {
    super("No connection to the network")
  }

  getI18nMsg(): string {
    return "error.noConnection"
  }
}