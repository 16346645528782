/* eslint-disable consistent-return */

import axios from "axios"
import FileSaver from "file-saver"

import { mapObjectToFormData } from "src/utils/fileHelper"
import { handleNetworkError } from "src/utils/errorHandler"
import Job, { JobDetails, JobStatus } from "src/models/job"

interface JobParams {
    offset: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string,
    statuses: string,
}

interface JobResponse {
    totalCount: number
    results: JobDetails[]
}

const ENDPOINT_PREFIX = "/api/wfl/"
const DEFAULT_LIMIT = 6
const MT_FILE_PREFIX = "machine translated"

export async function getJobs(type: JobStatus[], offset = 0, limit: number = DEFAULT_LIMIT): Promise<JobResponse> {
  try {
    const url = ENDPOINT_PREFIX + "workflow/requester/jobs"
    const params: JobParams = {
      offset, limit,
      statuses: type.join(",")
    }
    const resp = await axios.get(url, { params })
    const { totalCount, results } = resp.data
    return { totalCount, results }
  } catch (e) {
    handleNetworkError(e)
  }
}

export async function createJob(job: Job) {
  try {
    const url = ENDPOINT_PREFIX + "workflow/requester/job"
    const form = mapObjectToFormData(job)
    return (await axios.post(url, form)).data
  } catch (e) {
    handleNetworkError(e)
  }
}

export async function downloadFile(stageId: string, isMtEnhanced = true) {
  try {
    const url = ENDPOINT_PREFIX + `stage/${stageId}/outputFile`
    const params = { fileType: "RESULT" }
    const resp = await axios.get(url, { responseType: 'blob', params })
    const contentType = resp.headers['content-type']
    let [, fileName] = resp.headers['content-disposition'].split(';')[1].split('=')
    if(!isMtEnhanced) { fileName = `${MT_FILE_PREFIX} - ${fileName}` }
    FileSaver.saveAs(new Blob([resp.data], { type: contentType }), fileName)
  } catch (e) {
    handleNetworkError(e)
  }
    
}
