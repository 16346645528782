/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import messages_en from './locales/en'
import messages_pl from './locales/pl'

i18n.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  lng: 'en',
  fallbackLng: 'en',
  defaultNS: 'common',
  resources: {
    en: messages_en,
    pl: messages_pl
  }
})

export default i18n