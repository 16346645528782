import React from "react"
import { InfoOutlined } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
interface Props {
    maxFileSize: string,
}
const FileUploadLabel: React.FC<Props> = ({ maxFileSize }) => {
  const { t } = useTranslation('common')
    
  return (<>
    <h3>Upload file</h3>
    <div className="hint file-upload--limitations">
      <p>File formats: .pdf, .docx, .odt</p>
      <p>Size limit: {maxFileSize}</p>
    </div>
    <div className="hint flex file-upload--notice">
      <InfoOutlined />
      <p>{t('document.file.description')}</p>
    </div>
  </>)
}

export default FileUploadLabel
