export const authMessages = {
  loader: "Please wait...",
  signupDialog: "User created successfully! You will be redirected to login page",
  passwordForgetDialog: "Password reset link was sent to {{mail}}. Soon you will be redirected to login page",
  accountDescriptions: {
    login: "Username should contain at least 6 characters and max 60 characters. Only letters and digits are allowed",
    name: "First Name should contain at least 2 characters and max 60 characters. Only letters are allowed",
    surname: "Surname should contain at least 2 characters and max 60 characters. Only letters are allowed",
    email: "Email is invalid. Example: test@gmail.com",
    timezone: "Timezone must be selected",
    password: "Password must contain minimum 8 characters, at least one letter, one number and one special character",
    passwordConfirmation: "Password confirmation must be the same as password field"
  }
}