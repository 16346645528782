import React from "react"
import { useNavigate } from "react-router-dom"
import { Logout, Person } from "@mui/icons-material"
import { Avatar, Menu, MenuItem } from "@mui/material"

interface Props {
    username: string
}
const AccountMenu: React.FC<Props> = ({ username }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (<>
    <Avatar sx={{ bgcolor: '#214a7c' }} onClick={handleClick}>{username.substring(0, 1).toUpperCase()}</Avatar>
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      <MenuItem onClick={() => navigate('/profile')}>
        <Person /> Profile
      </MenuItem>
      <MenuItem onClick={() => navigate('/logout')}>
        <Logout /> Logout
      </MenuItem>
    </Menu>
  </>)
}

export default AccountMenu