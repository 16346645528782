/* eslint-disable @typescript-eslint/no-namespace */
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Tab } from "@mui/material"
import React, { DOMAttributes } from "react"
import PageContainer from "src/layout/PageContainer"
import { generateTokenForExfluency as generateToken } from "src/utils/tokenHelper"
class TableauElement extends HTMLElement {

  static get observedAttributes() {
    return ['token', 'url']
  }

  _token = ''
  _url = ''

  constructor() {
    super()
  }

  //https://tableau-server.prod.app.exfluency-cloud.com/views/BI3/Overview?:origin=card_share_link&:embed=yes&:toolbar=no

  connectedCallback() {
    const x = this.attributes as any
    const t = x.token.value
    const url = x.url.value
    this.innerHTML = `<tableau-viz id="tableauViz"
    src=${url}
    toolbar="hidden"
    hide-tabs
    tooltip="no"
  token="${t}">
</tableau-viz>`
  }

  set token(value: string) {
    this._token = value
  }

  get token() {
    return this._token
  }

  set url(value: string) {
    this._url = value
  }

  get url() {
    return this._url
  }

  static get tag() {
    return 'my-component'
  }
}

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['my-component']: CustomElement<TableauElement>
    }
  }
}

customElements.define(TableauElement.tag, TableauElement)

const DashboardsPage: React.FC = () => {

  const [value, setValue] = React.useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <PageContainer title="BI Overview">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="IQ Lingua overview" value="1" />
            <Tab label="Language overview" value="2"/>
            <Tab label="Editing distances overview" value="3" />
            <Tab label="Time usage overview" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/BI3/Overview?:origin=card_share_link&:embed=yes&:toolbar=no&:tooltip=no"}></my-component></TabPanel>
        <TabPanel value="2"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/BI3/LanguageOverview?:origin=card_share_link&:embed=yes&:toolbar=no"}></my-component></TabPanel>
        <TabPanel value="3"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/BI3/EditingDistances?:origin=card_share_link&:embed=yes&:toolbar=no"}></my-component></TabPanel>
        <TabPanel value="4"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/BI3/AssignmentDelivery?:origin=card_share_link&:embed=yes&:toolbar=no"}></my-component></TabPanel>
      </TabContext>
    </PageContainer>
  )
}

export default DashboardsPage
