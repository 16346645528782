/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react"
import { JobDetails, JobStatus } from "@models/job"
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material"
import { IconButton, Skeleton } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLogger } from "src/providers/LoggerProvider"
import withSnackbar, { SnackbarProps } from "src/providers/SnackbarProvider"
import { getJobs } from "src/services/api/document"
import { snackbarErrorHandler } from "src/utils/errorHandler"
import FileDetailsBox from "./FileDetailsBox"
import { RequestError } from "src/models/errors"

const JOB_LIMIT = 6

interface Props extends SnackbarProps {
    jobStatuses: JobStatus[]
}
const FileBrowser: React.FC<Props> = (props) => {

  const [jobs, setJobs] = useState<JobDetails[]>([])
  const [activePage, setActivePage] = useState<number>(0)
  const [isNext, setIsNext] = useState<boolean>(false)
  const [isPrev, setIsPrev] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation('common')
  const { logger } = useLogger()

  useEffect(() => {
    fetchJobs(0)
  }, [])

  const fetchJobs = async (page: number) => {
    try {
      setLoading(true)
      const offset = page * JOB_LIMIT
      const { totalCount, results } = await getJobs(props.jobStatuses, offset, JOB_LIMIT)
      setJobs(results)
      setIsPrev(offset > 0)
      setIsNext((offset + results.length) < totalCount)
    } catch (e) {
      if(e instanceof RequestError && e.status !== 403) {
        snackbarErrorHandler(e, props, t)  
      }
      logger.error("System - Failed to fetch jobs", e)
    } finally {
      setLoading(false)
    }
  }

  const getNext = async () => {
    await fetchJobs(activePage + 1)
    setActivePage(activePage + 1)
  }

  const getPrev = async () => {
    if (activePage > 0) {
      await fetchJobs(activePage - 1)
      setActivePage(activePage - 1)
    }
  }

  const renderBrowser = () => {
    return (<>
      <IconButton className={`file-details--wrapper-prev ${!isPrev ? 'hidden' : ''}`} onClick={getPrev}>
        <ArrowBackRounded />
      </IconButton>
      <div className="flex-wrap full-width">
        {jobs.map(job =>
          <FileDetailsBox job={job} key={job.id} downloadable={props.jobStatuses.includes("FINISHED")} />
        )}
      </div>
      <IconButton className={`file-details--wrapper-next ${!isNext ? 'hidden' : ''}`} onClick={getNext}>
        <ArrowForwardRounded />
      </IconButton>
    </>)
  }

  const renderSkeleton = () => {
    return (<>
      <IconButton className="file-details--wrapper-prev hidden">
        <ArrowBackRounded />
      </IconButton>
      <div className="flex-wrap full-width">
        <Skeleton className="file-loader" />
        <Skeleton className="file-loader" />
        <Skeleton className="file-loader" />
        <Skeleton className="file-loader" />
        <Skeleton className="file-loader" />
        <Skeleton className="file-loader" />
      </div>
      <IconButton className="file-details--wrapper-prev hidden">
        <ArrowBackRounded />
      </IconButton>
    </>)
  }

  return (
    <div>
      <h2>{t(`files.${props.jobStatuses[0]}`)}</h2>
      <div className="flex-al-center file-details">
        {loading ?
                    renderSkeleton() :
                    jobs.length > 0 ?
                        renderBrowser() :
                        <p>No files to show</p>
        }
      </div>
    </div>

  )
}

export default withSnackbar(FileBrowser)
