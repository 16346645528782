/* eslint-disable no-multiple-empty-lines */
import { generateDueDate } from "src/utils/fileHelper"
import { FileType, Genre, StageAttachment, StageParameter, StageStatus, StageType } from "./common"

export type JobStatus = "IN_PROGRESS" | "FINISHED" | "BLOCKED" | "ERROR" | "CANCELLED";
export type JobType = "COMMERCIAL" | "EXAMINATION" | "CHARITY";


export interface JobDetails {

    id: string,
    fileName: string,
    genre: Genre,
    status: JobStatus,
    dueDate: string | null,
    deliveryDate: string | null,
    taxonomy: string[],
    stages: RequesterStageDashboardRecord[],
    testMode: boolean,
    uploadDate: string,
    langFrom: string,
    langTo: string,
    externalProjectId: string
}

interface Stage {
    stage: StageType,
    executorSelection?: "manual" | "auto"
}

export interface RequesterStageDashboardRecord {
    id: string,
    stageType: StageType,
    stageStatus: StageStatus,
    assignments: any[],
    parameters: Record<StageParameter, string>,
    availableFileTypes: FileType[],
    readyFileTypes: FileType[],
    attachments: StageAttachment[]
}

class Job {

  file: any
  genre: Genre
  langFrom: string
  langTo: string
  taxonomy: string
  open: boolean
  companyVisibility: boolean
  communities: string
  dueDate: string
  stages: string
  externalProjectId?: string | undefined
  requesterNote?: string | undefined
  type: JobType
  externalDeadline?: string | undefined
  name?: string | undefined
  tableParsingConfig?: string | undefined

  constructor(builder: any) {
    this.file = builder.file
    this.langFrom = builder.langFrom
    this.langTo = builder.langTo
    this.genre = builder.genre
    this.taxonomy = builder.taxonomy
    this.open = builder.open
    this.companyVisibility = builder.companyVisibility
    this.communities = builder.communities.join(",")
    this.dueDate = builder.dueDate
    this.stages = builder.stages
    this.externalProjectId = builder.externalProjectId
    this.requesterNote = builder.requesterNote
    this.type = builder.type
    this.externalProjectId = builder.externalDeadline
    this.name = builder.name
    this.tableParsingConfig = builder.tableParsingConfig
  }
}

export class JobBuilder {

  private file: any
  private genre: Genre
  private langFrom: string
  private langTo: string
  private taxonomy: string
  private open: boolean
  private companyVisibility: boolean
  private communities: string[]
  private dueDate: string
  private stages: string
  private externalProjectId?: string | undefined
  private requesterNote?: string | undefined
  private type: JobType
  private externalDeadline?: string | undefined
  private name?: string | undefined
  private tableParsingConfig?: string | undefined
  private tempStages: any[]

  constructor(langFrom: string, langTo: string, file: any, taxonomy = 'Health') {
    this.file = file
    this.langFrom = langFrom
    this.langTo = langTo
    this.taxonomy = "Category:" + taxonomy
    this.genre = "life_sciences"
    this.open = false
    this.companyVisibility = false
    this.communities = []
    this.dueDate = generateDueDate()
    this.stages = ""
    this.type = "CHARITY"
    this.tempStages = []
  }

  public build(): Job {
    return new Job(this)
  }

  public addComunity(community: string) {
    this.communities.push(community)
    return this
  }

  public setStages(stages: StageType[]) {
    this.tempStages = []
    stages.forEach(stage => {
      const s: Stage = { stage }
      if (["REDACTION", "ENHANCEMENT", "TRUST_MINING", "CORRECTION", "FINAL_REDACTION"].includes(stage)) {
        s.executorSelection = "auto"
      }
      this.tempStages.push(s)
    })
    this.stages = JSON.stringify(this.tempStages)
    return this
  }

  public addAllStages(isPdf = false) {
    const stages: StageType[] = [
      'DOCUMENT_PARSING', 'ANONYMIZATION', 'REDACTION', 'RECYCLING',
      'ENHANCEMENT', 'TRUST_MINING', 'FINAL_REDACTION']
    if (isPdf) stages.unshift('OCR')
    this.setStages(stages)
    return this
  }

  /**
   * Add Human Enhanced Stages
   * 
   * Hardcoded configuration for S&C requirements
   * @param isPdf 
   */
  public addHETStages(isPdf = false) {
    const stages: StageType[] = [
      'DOCUMENT_PARSING', 'ANONYMIZATION', 'REDACTION', 'RECYCLING',
      'ENHANCEMENT', 'FINAL_REDACTION']
    if (isPdf) stages.unshift('OCR')
    this.setStages(stages)
    return this
  }

  /**
   * Add Machine Translation Stages
   * 
   * Hardcoded configuration for simple MT S&C flow
   * @param isPdf 
   */
  public addMTStages(isPdf = false) {
    const stages: StageType[] = [
      'DOCUMENT_PARSING', 'ANONYMIZATION', 'REDACTION', 'RECYCLING'
    ]
    if (isPdf) stages.unshift('OCR')
    this.setStages(stages)
    return this
  }

  public setExternalProjectId(projectId: string) {
    this.externalProjectId = projectId
    return this
  }

}

export default Job