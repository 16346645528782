/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { ArrowBack } from "@mui/icons-material"
import { Button } from "@mui/material"

import TranslationAsset from "@models/translationAsset"
import { isTranslationSupported, translateAssetPost } from "src/services/api/translate"
import { ExfluencyLoaderProps, SnackbarProps, useLogger, withExfluencyLoader, withSnackbar } from "src/providers"
import { snackbarErrorHandler } from "src/utils/errorHandler"

import PageContainer from "src/layout/PageContainer"
import LanguagePairSelector from "src/components/LanguagePairSelector"
import TranslationInput from "./TranslationInput"
import TranslationResult from "./TranslationResult"

import { detectTextLanguage, matchSupportedLang, xlfTokenRemover } from "src/utils/langHelper"

const TYPING_DELAY = 1000

const TranslateTextPage: React.FC<SnackbarProps & ExfluencyLoaderProps> = (props: SnackbarProps & ExfluencyLoaderProps) => {
  const { showLoader, closeLoader } = props
  let [asset, setAsset] = useState<TranslationAsset>({ langSource: "en_us", langTarget: "pl", text: "" })
  let [supported, setSupported] = useState<boolean>(true)
  let [translated, setTranslated] = useState<boolean>(false)
  const [langSuggestion, setLangSuggestion] = useState<string>("")
  const [translateDelay, setTranslateDelay] = useState<any>()
  const [result, setResults] = useState<string>("")
  const [disabledTranslation, setDisabledTranslation] = useState<boolean>(false)

  const { t } = useTranslation('common')
  const { logger } = useLogger()

  useEffect(() => {
    initLang()
  }, [])

  const initLang = () => {
    const localeLang = matchSupportedLang(window.navigator.language, "en_us")
    if (localeLang.includes("uk")) {
      setAsset({
        ...asset,
        langSource: "uk",
        langTarget: "pl"
      })
    } else {
      setAsset({
        ...asset,
        langSource: "uk",
        langTarget: localeLang
      })
    }
  }

  const doTranslate = () => {
    if (!asset.text || !supported || !asset.langSource || !asset.langTarget) return
    checkEngineSupport()
    translate()
  }

  const translate = async () => {
    try {
      showLoader(t('translate.loader'))
      let res
      res = await translateAssetPost(asset) || ''
      setResults(xlfTokenRemover(res, t('translate.anonymized')))
      setTranslated(true)
    } catch (e) {
      snackbarErrorHandler(e, props, t, () => {
        props.snackbarShowMessage(t("translate.error"), 'error')
      })
      logger.error("User - Failed to translate")
    } finally {
      closeLoader()
    }
  }

  const onTextUpdate = (text: string) => {
    setAsset({ ...asset, text })
    startDetectionDelay(text)
    if (text === '') setResults('')
  }

  const onResultUpdate = (text: string) => {
    setResults(text)
  }

  const checkEngineSupport = async () => {
    try {
      if (asset.langSource === "auto") return
      const sup = await isTranslationSupported(asset) || false
      if (!sup) props.snackbarShowMessage(t("translate.notSupported"), 'warning')
      setSupported(sup)
    } catch (e) {
      snackbarErrorHandler(e, props, t)
      logger.error("System - Failed to check engine support")
    }
  }

  const startDetectionDelay = (text: string) => {
    if (translateDelay) {
      clearTimeout(translateDelay)
      setTranslateDelay(setTimeout(() => detect(text), TYPING_DELAY))
    } else {
      setTranslateDelay(setTimeout(() => detect(text), TYPING_DELAY))
    }
  }

  const detect = async (text: string): Promise<void> => {
    try {
      if(disabledTranslation || !text) return
      let detectedLang = await detectTextLanguage(text)
      setLangSuggestion(
                detectedLang ?
                    matchSupportedLang(detectedLang) :
                    ''
      )
    } catch (e) {
      snackbarErrorHandler(e, props, t, () => {
        props.snackbarShowMessage(t("translate.error"), 'error')
      })
      logger.error("System - Failed to detect language")
    }
  }

  const goBack = (): void => {
    setTranslated(false)
    setResults('')
  }

  const changeLanguage = (lang: string, source: boolean) => {
        source ?
            setAsset({ ...asset, langSource: lang }) :
            setAsset({ ...asset, langTarget: lang })
  }

  const swapLanguages = (source: string, target: string): void => {
    setAsset({ ...asset, langTarget: target, langSource: source })
  }

  return (
    <PageContainer title={t('translate.title')}>
      <div className="flex-column">
        <div className="flex translate-header">
          <LanguagePairSelector
            langSource={asset.langSource}
            langTarget={asset.langTarget}
            disabled={!!translated}
            hint={langSuggestion}
            onLangSourceChange={(e) => changeLanguage(e, true)}
            onLangTargetChange={(e) => changeLanguage(e, false)}
            onLangSwap={swapLanguages}
          />
        </div>

        <div className="flex translate-content">
          <TranslationInput disabled={!!translated} onUpdate={onTextUpdate} onError={(b) => setDisabledTranslation(b)} />
          {!!translated && <TranslationResult disabled={!supported} value={result} editable={true} onModify={onResultUpdate} />}
        </div>

        <div className="flex-jc-center">
          {
                        translated ?
                            <Button variant="outlined" onClick={goBack}><ArrowBack /> {t('translate.back')}</Button> :
                            <Button variant="contained" disabled={!asset.text || disabledTranslation} onClick={doTranslate}> {t('translate.title')}</Button>
          }
        </div>
      </div>
    </PageContainer>
  )
}

export default withExfluencyLoader(withSnackbar(TranslateTextPage))