
import React from "react"
import { useTranslation } from "react-i18next"

import PageContainer from "src/layout/PageContainer"
import FileBrowser from "./FileBrowser"

const MyFilesPage: React.FC = () => {    
  const { t } = useTranslation('common')

  return (
    <PageContainer title={t('files.title')}>
      <div>
        <FileBrowser jobStatuses={['IN_PROGRESS', 'BLOCKED']}/>
        <FileBrowser jobStatuses={["FINISHED", "ERROR", "CANCELLED"]}/>
      </div>
    </PageContainer>
  )
}

export default MyFilesPage