import React from "react"
import { SwapHoriz } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"

import LanguageSelect from "./LanguageSelect"

interface Props {
    langSource: string,
    langTarget: string,
    disabled?: boolean,
    hint?: string,
    onLangSourceChange: (lang: string) => void,
    onLangTargetChange: (lang: string) => void,
    onLangSwap: (langSource: string, langTarget: string) => void
}
const LanguagePairSelector: React.FC<Props> = ({
  langSource, langTarget, disabled, hint, onLangSourceChange, onLangTargetChange, onLangSwap
}) => {
  const { t } = useTranslation('common')

  return (<>
    <LanguageSelect
      label={t('langFrom')}
      selected={langSource}
      disabled={disabled || false}
      hint={hint}
      onChanged={onLangSourceChange}
    />

    <IconButton onClick={() => onLangSwap(langTarget, langSource)} disabled={disabled || false}>
      <SwapHoriz></SwapHoriz>
    </IconButton>

    <LanguageSelect
      label={t('langTo')}
      selected={langTarget}
      disabled={disabled || false}
      onChanged={onLangTargetChange}
    />
  </>)
}

export default LanguagePairSelector
