/* eslint-disable @typescript-eslint/no-empty-function */
import { Community, UserProfile } from "@models/user"
import React, { createContext, useState } from "react"

interface IUserContext {
    user: UserProfile | null
    setUser: (user: UserProfile | null) => void
    userCommunities: Community[] | null
    setUserCommunities: (communities: Community[] | null) => void
}
export const UserContext = createContext<IUserContext>({ user: null, setUser: () => {}, userCommunities: [], setUserCommunities: () => {} })

interface Props {
    children: React.ReactNode
}
export const UserProvider = ({ children }: Props) => {
  const [user, setUser] = useState<UserProfile | null>(null)
  const [userCommunities, setUserCommunities] = useState<Community[] | null>(null)

  return (
    <UserContext.Provider value={{ user, setUser, userCommunities, setUserCommunities }} >
      { children }
    </UserContext.Provider>
  )
}