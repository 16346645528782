/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Clear } from "@mui/icons-material"
import { IconButton, TextField } from "@mui/material"
import TranslationResult from "./TranslationResult"

const MAX_INPUT_LENGTH = 1500

interface Props {
    disabled: boolean,
    onUpdate: (v: string) => void
    onError?: (active: boolean) => void
}
const TranslationInput = (props: Props) => {
  const { disabled } = props
  const [asset, setAsset] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const { t } = useTranslation('common')

  useEffect(() => {
    setErrorMsg(asset.length > MAX_INPUT_LENGTH ? 
            t('inputLimit', { val: MAX_INPUT_LENGTH }) :
            '')
  }, [asset])

  useEffect(() => {
    if(!!props.onError) {
      props.onError(!!errorMsg)
    }
  }, [errorMsg])

  const onAssetUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const v = e.target.value
    setAsset(v)
    props.onUpdate(v)
  }

  const clearForm = () => {
    setAsset('')
    props.onUpdate('')
  }

  const render = () => {
    if (disabled)
    {return (<TranslationResult value={asset} loading={true} />)}
    return (
      <div className={`asset-container ${disabled ? 'asset-container--disabled' : ''}`}>
        <TextField
          variant="outlined"
          multiline
          autoFocus
          fullWidth
          disabled={disabled}
          value={asset}
          onChange={onAssetUpdate}
          error={!!errorMsg}
          helperText={errorMsg}
        ></TextField>
        {!!asset &&
                    (<IconButton className="asset-container--toolbox asset-container--toolbox-top" onClick={clearForm} sx={{ p: '10px' }} aria-label="menu">
                      <Clear />
                    </IconButton>)}
      </div>
    )
  }

  return render()

}
export default TranslationInput