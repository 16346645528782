import React, { useEffect, useState } from "react"
import { Button, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { withDialog, DialogProps } from "src/providers"
import { changePassword } from "src/services/api/auth"

const PasswordChange:React.FC<DialogProps> = ({ showDialog }) => {

  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [invalidNewPass, setInvalidNewPass] = useState<string>('')
  const [newPassword2, setNewPassword2] = useState<string>('')
  const [invalidNewPass2, setInvalidNewPass2] = useState<string>('')

  const [isFormInvalid, setFormInvalid] = useState<boolean>(false)

  const isValidPassword = (password: string) => { return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{8,60}$/.test(password) }
  const isValidPasswordConfirm = (password: string, passwordConfirmation: string) => { return password === passwordConfirmation && password !== '' }

  const { t } = useTranslation('common')

  useEffect(() => {
    setInvalidNewPass(
            isValidPassword(newPassword) ? '' :
                "Wrong password"
    )
    setInvalidNewPass2(
            isValidPasswordConfirm(newPassword, newPassword2) ? '' :
                "Passwords does not match"
    )
    setFormInvalid(!!invalidNewPass || !!invalidNewPass2)
  }, [newPassword, newPassword2])

  useEffect(() => {
    setFormInvalid(!!invalidNewPass2)
  }, [invalidNewPass2])

  useEffect(() => {
    setFormInvalid(!isValidPassword(oldPassword))
  }, [oldPassword])

  const changeUserPassword = async () => {
    try {
      await changePassword(oldPassword, newPassword)
      showDialog(t("password.changeSuccess"), true)
    } catch (e) {
      console.error(e)
      showDialog(t("password.changeError"))
    }
  }

  return (
    <form className="password-change--container">
      <TextField
        label={t("password.current")}
        type="password"
        value={oldPassword}
        onChange={(e) => { setOldPassword(e.target.value) }}
        fullWidth
      ></TextField>
      <TextField
        label={t("password.new")}
        type="password"
        value={newPassword}
        onChange={(e) => { setNewPassword(e.target.value) }}
        error={!!invalidNewPass}
        helperText={invalidNewPass}
        fullWidth
      ></TextField>
      <TextField
        label={t("password.newRepeat")}
        type="password"
        value={newPassword2}
        onChange={(e) => { setNewPassword2(e.target.value) }}
        error={!!invalidNewPass2}
        helperText={invalidNewPass2}
        fullWidth
      ></TextField>
      <Button
        disabled={isFormInvalid}
        onClick={changeUserPassword}
      >
                Change password
      </Button>
    </form>
  )
}

export default withDialog(PasswordChange)