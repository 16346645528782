import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from 'src/providers'
import UserService from 'src/UserService'

const Logout: React.FunctionComponent = () => {
  const [, setLoading] = useState<boolean>(true)
  const { setUser, setUserCommunities } = useContext(UserContext)

  useEffect(() => {
    setUser(null)
    setUserCommunities(null)
    UserService.doLogout({ redirectUri: `${window.location.origin}` })
    document.cookie = 'ExfluencyCookie=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    setLoading(false)

  }, [])

  return <p>Loading please wait</p>

}

export default Logout