/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import { Link, useLocation } from "react-router-dom"

interface Props {
  path: string,
  label: string,
  icon: React.ReactElement,
  external?: boolean
}
const NavButton: React.FC<Props> = ({ path, label, icon, external }) => {
  const { pathname } = useLocation()

  return (<>
    {external ? 
      <a target="_blank" href={path}>
        {icon}
        <span>{label}</span>
      </a> : (
      <Link to={path} className={pathname === path ? 'active' : ''}>
        {icon}
        <span>{label}</span>
      </Link>
    )}
  </>
  )

}

export default NavButton