import { AxiosError } from 'axios'

// `unknown` because we're not guaranteed we'd get specifically errors from the backend.
// Could be a network timeout and the structure will be different.
export const isAxiosError = (e: any): e is AxiosError<unknown> => {
  return (Boolean(e.isAxiosError)) && typeof e.config == "object"
}

export const hasErrorCode = (status: number, error: any) =>
  isAxiosError(error) && error.response?.status === status

export const isServerError = (e: AxiosError) => {
  if (!e.response) {
    return false
  }
  const { status } = e.response
  return status >= 500
}

export const isClientError = (e: AxiosError) => {
  if (!e.response) {
    return false
  }
  const { status } = e.response
  return status >= 400 && status < 500
}