/* eslint-disable consistent-return */
import axios from "axios"
import { Community, UserProfile } from "@models/user"
import { handleNetworkError } from "src/utils/errorHandler"

const ENDPOINT_PREFIX = "/api/wfl/"

export async function getUserDetails(): Promise<UserProfile> {
  try {
    const url = ENDPOINT_PREFIX + "my_exfluency/settings/profile"
    const resp = await axios.get(url)
    return resp.data
  } catch (e) {
    handleNetworkError(e)
  }
}
export async function getUserCommunities(): Promise<Community[]> {
  try {
    const url = ENDPOINT_PREFIX + "groups_management/communities/my"
    const params = { isMember: false }
    return (await axios.get(url, { params })).data
  } catch (e) {
    handleNetworkError(e)
  }
}