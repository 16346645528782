/* eslint-disable @typescript-eslint/no-empty-interface */
import React from "react"
import { createTheme, ThemeProvider } from "@mui/material"
import { I18nextProvider } from "react-i18next"
import { BrowserRouter } from "react-router-dom"
import { HTTPLogger } from "src/utils/logger/HTTPLogger"

import i18n from "../i18n"
import { LoggerProvider } from "./LoggerProvider"
import { UserProvider } from "./UserContext"

const theme = createTheme({
  palette: {
    primary: {
      light: '#4daae7',
      main: '#214a7c',
      dark: '#1A237E'
    },
    secondary: {
      main: '#FCE94E'
    },
    error: {
      main: '#B00020'
    },
    success: {
      main: '#019592'
    }
  }
})
interface Props {
  children: React.ReactNode
}
const AppProviders = ({ children }: Props) => {
  const logger = new HTTPLogger()

  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <LoggerProvider logger={logger}>
          <BrowserRouter>
            <I18nextProvider i18n={i18n}>
              {children}
            </I18nextProvider>
          </BrowserRouter>
        </LoggerProvider>
      </ThemeProvider>
    </UserProvider>
  )
}
export default AppProviders