import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material"

import { DialogProps, ExfluencyLoaderProps, useLogger, UserContext, 
  withDialog, withExfluencyLoader } from "src/providers"

import { createJob } from "src/services/api/document"
import TranslationAsset from "src/models/translationAsset"
import { JobBuilder } from "src/models/job"
import { RequestError } from "src/models/errors"
import PageContainer from "src/layout/PageContainer"
import LanguagePairSelector from "src/components/LanguagePairSelector"
import { matchSupportedLang } from "src/utils/langHelper"

import FileUpload from "./FileUpload"
import SummaryDialog, { SummaryDialogResults } from "./SummaryDialog"

type FlowType = "MT" | "HET"

const TranslateFilePage: React.FC<DialogProps & ExfluencyLoaderProps> = ({ showDialog, showLoader, closeLoader }) => {

  const [asset, setAsset] = useState<TranslationAsset>({ langSource: "en_us", langTarget: "pl", text: "" })
  const [file, setFile] = useState<any>()
  const [fileId, setFileId] = useState<number>(0)
  const [dialogSummary, setDialogSummary] = useState<boolean>(false)
  const [flowType, setFlowType] = useState<FlowType>("MT")

  const { userCommunities } = useContext(UserContext) 
    
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const { logger } = useLogger()

  useEffect(() => {
    initLang()
  }, [])

  const initLang = () => {
    const localeLang = matchSupportedLang(window.navigator.language, "en_us")
    if(localeLang.includes("uk")) {
      setAsset({ ...asset,
        langSource: "uk",
        langTarget: "pl" })
    } else {
      setAsset({ ...asset, 
        langSource: "uk",
        langTarget: localeLang })
    }
  }

  const handleDialogSummary = (e: SummaryDialogResults) => {
    setDialogSummary(false)
    if(e === "SHOW_FILES") {
      navigate("/files")
    }
  }

  const sendDocument = async (machineTranslation: boolean) => {
    try {
      showLoader(t('document.loader'))
      if (!file) return
      if(!userCommunities || userCommunities.length === 0) {
        showDialog(t('document.communityError'))
        return
      }
      const isPdf = file.type === "application/pdf"
      const job = new JobBuilder(asset.langSource, asset.langTarget, file)
        .setExternalProjectId("Aid")
      if(machineTranslation) {
        job.addMTStages(isPdf)
      } else {
        job.addAllStages(isPdf)
      }

      userCommunities.forEach(c => {
        job.addComunity(c.gatedCommunityId)
      })

      await createJob(job.build())
      setDialogSummary(true)
      setFile(null)
      setFileId(fileId + 1)
    } catch (e) {
      const dialogMessage = (e instanceof RequestError && e.status === 401) ?
                t('document.permissionError') :
                t('document.uploadError')
      showDialog(dialogMessage)
      logger.error('User - Failed to upload document', e)
    } finally {
      closeLoader()
    }
  }

  const changeLanguage = (lang: string, source: boolean) => {
        source ?
            setAsset({ ...asset, langSource: lang }) :
            setAsset({ ...asset, langTarget: lang })
  }

  const swapLanguages = (source: string, target: string) => {
    setAsset({ ...asset, langTarget: target, langSource: source })
  }

  return (
    <PageContainer title={t('document.title')}>
      <div className="flex-column">
        <div className="flex translate-header">
          <LanguagePairSelector
            langSource={asset.langSource}
            langTarget={asset.langTarget}
            onLangSourceChange={(e) => changeLanguage(e, true)}
            onLangTargetChange={(e) => changeLanguage(e, false)}
            onLangSwap={swapLanguages}
          />
        </div>

        <div className="flex">
          <FileUpload 
            key={fileId} 
            onFileUploaded={(f) => { setFile(f) }}
            onError={(size) => showDialog(t('document.sizeLimit', { val: size }))}
          ></FileUpload>
        </div>

        <div className="flex-jc-center">
          <ToggleButtonGroup
            value={flowType}
            exclusive
            size="small"
            disabled={!file}
            onChange={(e, v) => setFlowType(v)}
            className="toggle-container"
          >
            <ToggleButton value={"HET"} className="toggle-button">
              <Tooltip title={<span>{t('document.sendHETDescription')}</span>} arrow>
                <span>{t('document.sendHET')}</span>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={"MT"} color="primary" className="toggle-button">
              <Tooltip title={<span>{t('document.sendMTDescription')}</span>} arrow>
                <span>{t('document.sendMT')}</span>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="flex-jc-center">
          <Tooltip title={<span>{t('document.sendDescription')}</span>} arrow>
            <Button disabled={!file || !flowType} onClick={() => {sendDocument(flowType === 'MT')}} variant="contained"><img src="icons/PbD_icon.svg" alt="Exfluency Shield" className={`small-icon ${(!file || !flowType) ? 'disabled' :''}`}/> Send File</Button>
          </Tooltip>
        </div>

        <SummaryDialog open={dialogSummary} onClose={handleDialogSummary}/>

      </div>
    </PageContainer>
  )
}

export default withExfluencyLoader(withDialog(TranslateFilePage))
