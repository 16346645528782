export const langMessages = {
  auto: "Wykryj automatycznie",
  pl: "polski",
  en: "angielski",
  ru_ua: "rosyjski (Ukraina)",
  af: "afrikaans",
  am: "amharski",
  ar: "arabski",
  az: "azerski / azerbejdżański (łaciński)",
  be: "białoruski",
  bg: "bułgarski",
  bn: "bengalski",
  bs: "bośniacki",
  ca: "kataloński",
  ceb: "cebuano",
  co: "korsykański",
  cs: "czeski",
  cy: "walijski",
  da: "duński",
  de_at: "niemiecki (Austria)",
  de_ch: "niemiecki (Szwajcaria)",
  de_de: "niemiecki",
  el_cy: "grecki (Cypr)",
  el_gr: "grecki",
  en_gb: "angielski (Wielka Brytania)",
  en_us: "angielski (USA)",
  eo: "esperanto",
  es: "hiszpański",
  es_419: "hiszpański (Ameryka Łacińska)",
  es_mx: "hiszpański (Meksyk)",
  et: "estoński",
  eu: "baskijski",
  fa: "Farsi",
  fa_af: "Farsi (Afghanistan)",
  fa_ir: "Farsi (Iran)",
  fi: "fiński",
  fil: "filipiński",
  fj: "Fidżi",
  fr_be: "francuski (Belgia)",
  fr_ca: "francuski (Kanada)",
  fr_ch: "francuski (Szwajcaria)",
  fr_fr: "francuski",
  fy: "zachodniofryzyjski",
  ga: "irlandzki",
  gd: "gaelicki szkocki",
  gl: "galicyjski",
  gu: "gudżarati",
  ha: "Hausa",
  he: "hebrajski",
  hi: "hinduski",
  hmn: "Hmong",
  hr: "chorwacki",
  ht: "haitański",
  hu: "węgierski",
  hy: "ormiański",
  id: "indonezyjski",
  ig: "Igbo",
  is: "islandzki",
  it_ch: "włoski (Szwajcaria)",
  it_it: "włoski",
  ja: "japoński",
  jv: "jawajski",
  ka: "gruziński",
  kk: "kazachski",
  kl: "grenlandzki",
  kln_ke: "Kalenjin",
  ko: "koreański",
  ku: "kurdyjski",
  ku_arab: "kurdyjski (arabski)",
  ky_kg: "Kirgistan (Kirgistan)",
  kz: "Kirgistan",
  la: "łacina",
  lb: "luksemburski",
  lo: "laotański",
  lt: "litewski",
  lv: "łotewski",
  mg: "malgaski",
  mi: "maoryski",
  mk: "macedoński",
  ml: "malajalam",
  mn: "mongolski (cyrylica)",
  mr: "Marathi",
  ms: "malajski",
  mt: "maltański",
  my: "burmański",
  ne: "nepalski",
  nl_be: "holenderski (Belgia)",
  nl_nl: "holenderski (Holandia)",
  no: "norweski",
  ny: "Chewa",
  pa_arab_pk: "pendżabski (Pakistan, arabski)",
  pa_in: "pendżabski (Indie)",
  pa_pk: "pendżabski (Pakistan)",
  prs: "Dari",
  ps: "paszto",
  pt_br: "portugalski (Brazylia)",
  pt_pt: "portugalski",
  ro_md: "rumuński (Mołdawia)",
  ro_ro: "rumuński",
  ru_ru: "rosyjski",
  sd: "Sindhi",
  si: "syngaleski",
  sk: "słowacki",
  sl: "słoweński",
  sm: "samoański",
  sn: "szona",
  so: "somalijski",
  sq: "albański",
  sr_cyrl: "serbski (cyrylica)",
  sr_latn: "serbski (łaciński)",
  st: "Południowe Sotho",
  su: "sundajski",
  sv_fi: "szwedzki (Finlandia)",
  sv_se: "szwedzki",
  sw: "suahili",
  ta: "tamilski",
  ta_in: "tamilski (Indie)",
  te: "telugu",
  tg: "tadżycki",
  tg_cyrl: "tadżycki (cyrlica)",
  th: "tajski",
  tl: "tagalski",
  thl: "klingoński",
  to: "Tonga",
  tr: "turecki",
  uk: "ukraiński",
  ur: "Urdu",
  uz_cyrl_uz: "uzbecki (cyrylica)",
  uz_latin_uz: "uzbecki (łaciński)",
  vi: "wietnamski",
  xh: "Xhosa",
  yi: "jidysz",
  yo: "Joruba",
  zh_hans: "chiński (uproszczony)",
  zh_hant: "chiński (tradycyjny)",
  zu: "zuluski"
}