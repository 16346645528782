/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-destructuring */
import { languagesDictionary } from "src/models/languages"
import { detectLanguagePost } from "src/services/api/translate"

export function sortByLabel(a:string, b:string, t: Function) {
  if(t(`${a}`).toLowerCase() < t(`${b}`).toLowerCase()) {
    return -1
  }
  if(t(`${a}`).toLowerCase() > t(`${b}`).toLowerCase()) {
    return 1
  }
  return 0
}

export function matchSupportedLang(lang:string, defaultLanguage = "") {
  if(lang) {
    const separator = lang.includes("-") ? "-" : "_"
    const [base, variant] = lang.split(separator)

    let supportedLanguage = defaultLanguage
    const langDictionary = languagesDictionary.filter(l => l.startsWith(base.toLowerCase()))
        
    if(langDictionary.length === 1) {
      supportedLanguage = langDictionary[0]
    } else if (langDictionary.length > 1) {
      if(variant) {
        const matcher = `${base.toLowerCase()}_${variant.toLowerCase()}`
        const result = langDictionary.find(l => l === matcher)
        supportedLanguage = result ? result : langDictionary[0]
      } else {
        supportedLanguage = langDictionary[0]
      }
    }
    
    return supportedLanguage
  }
  return lang
}

export function detectTextLanguage(text:string): Promise<string> {
  return new Promise(async (resolve, reject) => {
    try {
      if(!text) resolve('')
      const resp = await detectLanguagePost(text)
      if(!resp) reject(new Error("Detection failed"))
      resolve(resp.lang)
    } catch (e) {
      reject(e)
    }
  })
}

export function xlfTokenRemover(text: string, replaceText = "[ANONYMIZED] "): string {
  const TOKEN_UUID_PATTERN = /XFL_TOKEN_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gm
  return text.replaceAll(TOKEN_UUID_PATTERN, replaceText)
}

export function escapeCharacters(text:string, revert = false) {
  const escapeCharacters = new Map()
  escapeCharacters.set("[", "%5B").set("]", "%5D")
    .set("<", "%3C").set(">", "%3E")
    .set("{", "%7B").set("}", "%7D")
    .set("$", "%24").set("&", "%26")
    .set("?", "%3F").set("#", "%23")
    
  escapeCharacters.forEach((v,k) => {
    text = revert ? 
            text.replaceAll(v,k) :
            text.replaceAll(k, v)
  })
  return text
}