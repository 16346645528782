/* eslint-disable react/display-name */
import React, { useState } from "react"
import ErrorDialog from "src/components/dialogs/ErrorDialog"

export interface DialogProps {
    showDialog: (message: string, info?: boolean) => void;
}
const withDialog = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: any) => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [isInfo, setInfo] = useState<boolean>(false)

    const showDialog = (message: string, info = false) => {
      setMessage(message)
      setOpen(true)
      setInfo(info)
    }

    const handleClose = () => {
      setOpen(false)
    }

    return (<>
      <WrappedComponent {...props as P} showDialog={showDialog} />
      <ErrorDialog open={open} onClose={handleClose} message={message} info={isInfo} />
    </>)
  }
}

export default withDialog
