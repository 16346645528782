/* eslint-disable require-await */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-invalid-this */

import { LogLevel } from "@models/logger"
import { sendLog, sendUserInfo } from "src/services/api/logger"
import { getUserErrorMessage } from "./errorUser"

export type LogParams = {
  [index: string]: any;
}
  
export interface Logger {
  send(level: LogLevel, message: string, params?: LogParams): void;
  debug(message: string, params?: LogParams): void;
  info(message: string, params?: LogParams): void;
  warn(message: string, params?: LogParams): void;
  error(message: string, error?: unknown, params?: LogParams): void;
  browserInfo(): void;
}

export class HTTPLogger implements Logger {
  private sendLog = async (logLevel: LogLevel, message: string, params?: LogParams) => {
    const path = window.location.pathname
    
    if(process.env.REACT_APP_LOGGER_ENABLED?.toLowerCase() === 'true') {
      sendLog(logLevel, message, path, params ?? {})
    } else {
      console.log(message)
      if(params) console.error(JSON.stringify(params))
    }
  }
  private sendClientLog = async (params: LogParams) => {
    if(process.env.REACT_APP_LOGGER_ENABLED?.toLowerCase() === 'true') {
      sendUserInfo(params)
    } else {
      console.log(JSON.stringify(params))
    }
  }

  send = (level: LogLevel, message: string, params?: LogParams) => {
    this.sendLog(level, message, params)
  }

  debug = (message: string, params?: LogParams) => {
    this.send("DEBUG", message, params)
  }

  info = (message: string, params?: LogParams) => {
    this.send("INFO", message, params)
  }

  warn = (message: string, params?: LogParams) => {
    this.send("WARN", message, params)
  }

  error = (message: string, error?: unknown, params?: LogParams) => {
    if(error) {
      const errorMsg = getUserErrorMessage(error)
      this.send("ERROR", message, { ...params, error: errorMsg })
    } else {
      this.send("ERROR", message, params)
    }
  }

  browserInfo = () => {
    const newNavigator = navigator as any
    const settings = {
      timezone: (new Date()).getTimezoneOffset() / 60,
      // screen
      sizeScreenW: screen.width,
      sizeScreenH: screen.height,
      sizeInW: innerWidth,
      sizeInH: innerHeight,
      // navigator
      connectionType: newNavigator.connection?.effectiveType ?? undefined,
      userAgent: newNavigator.userAgent,
      vendor: newNavigator.vendor,
      maxTouchPoints: newNavigator.maxTouchPoints,
      deviceMemory: newNavigator.deviceMemory ?? 0,
      language: newNavigator.language
    }
    this.sendClientLog(settings)
  }
}
