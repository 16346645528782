/* eslint-disable consistent-return */
import axios from "axios"
import { handleNetworkError } from "src/utils/errorHandler"

export interface Credentials {
    login: string,
    email: string
}

export interface CheckAvailability {
    login: string,
    email: string,
    unavailableLogin: boolean,
    unavailableEmail: boolean,
    validLogin: boolean,
    validEmail: boolean
}

export interface InsertExfluencyUser {
    login: string,
    name: string,
    surname: string,
    email: string,
    timezone: string,
    password: string,
    termsOfService: boolean,
    recaptchaToken: string,
}

const ENDPOINT_PREFIX = "/api/wfl/auth/"

export async function availability(creds: Credentials) {
  try {
    const url = ENDPOINT_PREFIX + 'signup/availability'
    return (await axios.post<CheckAvailability>(url, creds)).data
  } catch (e) {
    handleNetworkError(e)
  }
}

export async function signup(creds: InsertExfluencyUser) {
  try {
    const url = ENDPOINT_PREFIX + 'signup'
    return (await axios.post(url, creds)).data
  } catch (e) {
    handleNetworkError(e)
  }
}

export async function sendPasswordForgotMail(mail: string) {
  const userName = ""
  try {
    const url = ENDPOINT_PREFIX + 'password/forgot/send'
    return (await axios.post(url, undefined, { params: { userName, mail } })).data
  } catch (e) {
    handleNetworkError(e)
  }
}

export async function changePassword(oldPassword: string, newPassword: string) {
  try {
    const url = ENDPOINT_PREFIX + 'password/change'
    const params = { newPassword, oldPassword }
    return await axios.post(url, params)
  } catch (e) {
    handleNetworkError(e)
  }
}
