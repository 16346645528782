import React from "react"
import { Clear, InsertDriveFileOutlined } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { formatFileSize } from "src/utils/fileHelper"

interface Props {
    file: any,
    onFileRemoved: () => void
}
const FileUploadItem: React.FC<Props> = ({ file, onFileRemoved }) => {
    
  return (<div className="flex file-upload-item">
    <InsertDriveFileOutlined/>
    <div className="file-upload-item--details">
      <p>{file.name}</p>
      <p className="hint">{formatFileSize(file.size)}</p>
    </div>
    <IconButton onClick={() => onFileRemoved()} className="file-upload-item--actions" size="small">
      <Clear/>
    </IconButton>
  </div>)
}

export default FileUploadItem
