import React from "react"
const FileUploadDrop: React.FC = () => {
    
  return (<>
    <h3>Drop file here</h3>
    <div className="hint file-upload--limitations">
      <p>File formats: PDF, docx</p>
      <p>Size limit: 2MB</p>
    </div>
  </>)
}

export default FileUploadDrop
