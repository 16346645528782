/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-types */
import React from "react"
import { useTranslation } from "react-i18next"
import { ContentCopy } from "@mui/icons-material"
import { IconButton, TextField } from "@mui/material"

import withSnackbar, { SnackbarProps } from "src/providers/SnackbarProvider"

interface Props extends SnackbarProps {
    value: string,
    disabled: boolean,
    editable?: boolean
    onModify?: (text: string) => {}
}
const TranslationResult = (props: Props) => {
  let { value, editable } = props
  const { t } = useTranslation('common')

  const onCopyClick = () => {
    navigator.clipboard.writeText(value)
    onAssetCopy()
  }

  const onAssetCopy = () => {
    props.snackbarShowMessage(t('translate.copy'), 'info')
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
      onAssetCopy()
    }
  }

  const handleContextMenu = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault()
    return false
  }

  const onAssetUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value && props.onModify) {
      props.onModify(e.target.value)
    }
  }

  return (
    <div className="asset-container asset-result">
      <TextField
        className="asset-result--input"
        multiline
        fullWidth
        disabled={!editable}
        onContextMenu={handleContextMenu}
        onKeyDown={handleKeyDown}
        onChange={onAssetUpdate}
        value={value}
      ></TextField>
      {!!value && (
        <IconButton className="asset-container--toolbox asset-container--toolbox-btm" onClick={onCopyClick} sx={{ p: '10px' }} aria-label="menu">
          <ContentCopy />
        </IconButton>
      )}
    </div>
  )

}
export default withSnackbar(TranslationResult)