import { TFunction } from "i18next"
import { SnackbarProps } from "src/providers/SnackbarProvider"
import { NoConnectionError, RequestError } from "src/models/errors"

/**
 * Show error on UI using snackbar element
 *  
 * @param error Error instance
 * @param snackbar reference to component props with snackbar 
 * @param t Translation package
 * @param onError [optional] callback function invoked if any previous type was not invoked. 
 */
export function snackbarErrorHandler(error: any, snackbar: SnackbarProps, t:TFunction, onError = () => {console.error(error)}):void {
  console.error(error)
  if(error instanceof RequestError) {
    snackbar.snackbarShowMessage(t(error.getI18nCode()), 'error'); return}
  if(error instanceof NoConnectionError) {
    snackbar.snackbarShowMessage(t(error.getI18nMsg()), 'error'); return}
  onError()
}

/**
 * Handle network error (This method always throw an error)
 * 
 * Validate received error and create
 * a valid NetworkError Class instance. 
 * Each object has additional methods to debug
 * or process received error codes.
 *
 * @param error - Error instance to check.
 */
export function handleNetworkError(error: any): never {
  if (error.message === "Network Error") throw new NoConnectionError()
  if (error.response === undefined) throw error

  console.log(error.response)

  throw new RequestError(error.response)
}