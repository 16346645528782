export const commonMessages = {
  download: "Download",
  langFrom: "Source language",
  langTo: "Target language",
  translate: {
    title: "Translate text",
    copy: "Copied!",
    back: "Back",
    loader: "Translating using all Exfluency engines",
    error: "Failed to translate text",
    detectError: "Failed to detect language",
    notSupported: "This language pair is not supported",
    anonymized: "[Anonymized text] "
  },
  inputLimit: "Translated text can't be longer than {{val}} characters.",
  home: {
    title: "Document or text?",
    document: {
      title: "Translate document",
      description: "Upload a document and receive your translation quickly and securely.",
      action: "Translate document"
    },
    text: {
      description: "Insert text and get ad-hoc, immediate translation. Accuracy may vary/beta version."
    },
    footer: {
      title: "Willing to help?",
      description: "We are looking for translators and bilingual subject matter experts\n to help us develop the application.",
      action: "CONTACT US"
    }
  },
  document: {
    loader: "Uploading your file...",
    title: "Translate file",
    uploaded: "Uploaded file",
    uploadError: "Sorry, but we cannot process your request right now. Please try again later.",
    permissionError: "We only allow NGO's to Translate File - please make sure your company can add charity projects by reaching out to your Exfluency contact.",
    communityError: "Only Gated Community members can upload files.",
    sendMT: "Automated Translation",
    sendMTDescription: "Automated Translation is for urgent cases where immediate translation is required. This involves running the file through OCR, Redaction, Anonymization and Neural Machine Translation engines.",
    sendHET: "Human Enhanced Translation",
    sendHETDescription: "Human Enhanced Translation involves running the file through the entire Exfluency workflow: OCR, Redaction, Anonymization, Machine Translation and Subject Matter Expert Enhancers,",
    sendDescription: "All files will be anonymized to protect your data confidentiality",
    sizeLimit: "You can't upload file bigger than {{val}}!",
    file: {
      drop: "Drop file here!",
      name: "File name:",
      upload: "Upload file",
      replace: "Replace file",
      description: "You only can upload one file at a time. Selecting another file replaces the previous file."
    }
  },
  files: {
    title: "My files",
    downloadError: "Sorry, but we cannot download your document. Please try again later.",
    IN_PROGRESS: "In progress",
    FINISHED: "Completed",
    BLOCKED: "Blocked",
    enhanced: "Human Enhanced",
    machineTranslation: "Automated Translation",
    details: {
      BLOCKED: "There are no available executors",
      ERROR: "Error occured during file translation",
      CANCELLED: "File translation has been cancelled",
      subject: "Subject",
      uploaded: "Uploaded",
      deadline: "Deadline"
    }
  },
  profile: {
    title: "My profile",
    details: {
      logged: "Logged in as:",
      mail: "Mail",
      organization: "My organization",
      myCommunities: "My Gated Communities"
    }
  },
  password: {
    current: "Current password",
    changeSuccess: "Password has been changed succesfully",
    changeError: "Some error occurred during password change. Your password has not been changed",
    new: "New password",
    newRepeat: "Repeat new password"
  },
  error: {
    noConnection: "Network conection not available",
    request: {
      index: "Request error",
      400: "Bad Request - Check input data",
      401: "Request is not authorized",
      403: "This operation is not allowed",
      404: "Server is not available",
      414: "Request is too long",
      504: "Connection timed out",
      500: "Unexpected server error"
    }
  }
}