/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext } from 'react'
import { Logger } from 'src/utils/logger/HTTPLogger'

type LoggerContextValue = {
  logger: Logger
}

const initialValue: LoggerContextValue = {
  logger: {
    send: () => {},
    debug: () => {},
    info: () => {},
    warn: () => {},
    error: () => {},
    browserInfo: () => {}
  }
}

export const LoggerContext = createContext(initialValue)

interface LoggerProviderProps {
  logger: Logger
  children: React.ReactNode
}

export const LoggerProvider = ({ children, logger }: LoggerProviderProps) => (
  <LoggerContext.Provider value={{ logger }}>
    {children}
  </LoggerContext.Provider>
)

export const useLogger = () => useContext(LoggerContext)