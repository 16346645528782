import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@mui/material"

import { JobDetails, JobStatus } from "src/models/job"
import { getLastResultFileId, trimTitle } from "src/utils/fileHelper"
import { downloadFile } from "src/services/api/document"
import { Download } from "@mui/icons-material"
import withDialog, { DialogProps } from "src/providers/DialogProviders"

interface Props {
    downloadable?: boolean
    job: JobDetails
}
const FileDetailsBox: React.FC<Props & DialogProps> = ({ downloadable, job, showDialog }) => {
  const dueDate = job.dueDate ? new Date(job.dueDate) : new Date()
  const [resultFileId] = useState<string | undefined>(getLastResultFileId(job.stages))
  const { t } = useTranslation('common')

  const downloadResultFile = async () => {
    try {
      if (!resultFileId) throw new Error("File does not exits")
      await downloadFile(resultFileId, isMtEnhanced(job))
    } catch (e) {
      showDialog(t("files.downloadError"))
    }
  }

  const isErrorState = (jobStatus: JobStatus): boolean => {
    return ["BLOCKED", "ERROR", "CANCELLED"].includes(jobStatus)
  }

  const isMtEnhanced = ({ stages }: JobDetails) => {
    return !!stages.find(({ stageType }) => stageType === 'ENHANCEMENT')
  }

  return (
    <div className="card-container">
      <div className={`card-statusbar ${isMtEnhanced(job) ? 'card-statusbar--het' : 'card-statusbar--mt'}`}>
        {isMtEnhanced(job) ? t('files.enhanced') : t('files.machineTranslation')}
      </div>
      <h3>{trimTitle(job.fileName)}</h3>
      <div>
        {isErrorState(job.status) && <p><span className="bold">{t(`files.details.${job.status}`)}</span></p>}
        <p><span className="bold">{t('files.details.subject')}:</span> {job.taxonomy.join(', ')}</p>
        <p><span className="bold">{t('files.details.uploaded')}:</span> {new Date(job.uploadDate).toLocaleString()}</p>
        {!downloadable &&
                    <p><span className="bold">{t('files.details.deadline')}:</span> {dueDate.toLocaleString()}</p>
        }
      </div>
      {downloadable && !!resultFileId && (
        <div className="flex-jc-end">
          <Button onClick={downloadResultFile} endIcon={<Download />}>{t('download')}</Button>
        </div>
      )}

    </div>
  )
}

export default withDialog(FileDetailsBox)