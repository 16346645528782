/* eslint-disable react/display-name */
import { Alert, AlertColor, Snackbar } from "@mui/material"
import React, { useState } from "react"

export interface SnackbarProps {
    snackbarShowMessage: (message: string, severity?: AlertColor) => void;
}
const withSnackbar = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: any) => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("Snackbar")
    const [severity, setSeverity] = useState<AlertColor | undefined>('success')

    const showMessage = (message: string, severity: AlertColor = 'success') => {
      setMessage(message)
      setSeverity(severity)
      setOpen(true)
    }
    const handleClose = (event: any, reason: any) => {
      if (reason === 'clickaway') return
      setOpen(false)
    }

    return (
      <>
        <WrappedComponent {...props as P} snackbarShowMessage={showMessage} />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          autoHideDuration={5000}
          open={open}
          onClose={handleClose}
        >
          <Alert variant="filled" severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </>
    )
  }
}

export default withSnackbar