/* eslint-disable consistent-return */
import axios from "axios"

import TranslationEntry from "../../models/translationAsset"
import { handleNetworkError } from "src/utils/errorHandler"
import { escapeCharacters } from "src/utils/langHelper"

interface TranslateTextParams {
    text: string,
    langFrom: string,
    langTo: string,
}

interface TranslateSuppParams {
    langFrom: string,
    langTo: string
}
interface DetectedLanguage {
    lang: string,
    prob: number
}

const ENDPOINT_PREFIX = "/api/snc/"

export async function translateAssetPost(req: TranslationEntry):Promise<string> {
  try {
    const url = ENDPOINT_PREFIX + "translate/"
    const body: TranslateTextParams = {
      text: escapeCharacters(req.text),
      langFrom: req.langSource,
      langTo: req.langTarget
    }
    return escapeCharacters((await axios.post(url, body)).data[0].text, true)
  } catch (e) {
    handleNetworkError(e)
  }
}

export async function isTranslationSupported(req: TranslationEntry): Promise<boolean> {
  try {
    const url = ENDPOINT_PREFIX + "engineAvailability"
    const params: TranslateSuppParams = {
      langFrom: req.langSource,
      langTo: req.langTarget
    }
    const resp = await axios.get(url, { params })
    return (!!resp.data && resp.data.length > 0)
  } catch (e) {
    handleNetworkError(e)
  }
}

export async function detectLanguagePost(text: string): Promise<DetectedLanguage> {
  try {
    const url = ENDPOINT_PREFIX + `detectLanguage`
    return (await axios.post(url, text)).data[0]
  } catch (e) {
    handleNetworkError(e)
  }
}
