/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import i18n from "src/i18n"

import PageContainer from "src/layout/PageContainer"

import './style.css'
import PasswordChange from "./PasswordChange"
import { SnackbarProps, UserContext, withSnackbar } from "src/providers"

const ProfilePage: React.FC<SnackbarProps> = (props: SnackbarProps) => {
  const [, setLang] = React.useState<string>('en')
  const { user, userCommunities } = useContext(UserContext)

  const { t } = useTranslation('common')

  const selectLanguage = (e: any) => {
    const language = e.target.value
    setLang(language)
    i18n.changeLanguage(language)
  }

  return (
    <PageContainer title={t('profile.title')}
      footer={<p className="version-info">Application version: {process.env.REACT_APP_VERSION}</p>}
    >
      <div className="profile--container">

        {!!user && (
          <div className="profile-details">
            <div className="profile-details--entry">
              <p>{t('profile.details.logged')}</p>
              <p>{user.name} {user.surname} ({user.login})</p>
            </div>
            <div className="profile-details--entry">
              <p>{t('profile.details.mail')}</p>
              <p>{user.email}</p>
            </div>
            {!!user.company && (
              <div className="profile-details--entry">
                <p>{t('profile.details.organization')}</p>
                <p>{user.company.companyName}</p>
              </div>
            )}
            {!!userCommunities && userCommunities.length > 0 && (
              <div className="profile-details--entry">
                <p>{t('profile.details.myCommunities')}</p>
                <p>
                  <ul>
                    {userCommunities.map(c => <li key={c.gatedCommunityId}>{c.gatedCommunityName}</li>)}
                  </ul>
                </p>
              </div>
            )}
          </div>
        )}
        <div className="profile-settings">
          <PasswordChange />
        </div>
      </div>
    </PageContainer>
  )
}

export default withSnackbar(ProfilePage)