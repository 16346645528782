import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Autocomplete, Box, TextField } from "@mui/material"

import { languagesDictionary } from "src/models/languages"
import { sortByLabel } from "src/utils/langHelper"

interface Props {
    selected: string,
    label: string,
    disabled?: boolean,
    hint?: string,
    onChanged: (val: string) => void
}

const LanguageSelect: React.FC<Props> = ({ label, selected, disabled, hint, onChanged }: Props) => {
  const { t } = useTranslation('lang')
  const [inputVal, setInputVal] = useState<string>(t(selected))

  const options: readonly string[] = languagesDictionary.sort((a, b) => sortByLabel(a, b, t))

  const getLangSuggetionLabel = () => {
    if(!!hint && hint !== selected) {
      return <>{t('langSuggestion')}<br/>{t(hint)}</>
    }
    return ''
  }

  return (
    <Autocomplete
      autoHighlight
      value={selected}
      inputValue={inputVal}
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => t(`${option}`)}
      onInputChange={(e, v) => setInputVal(v)}
      onChange={(e, val) => onChanged(val || '')}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {t(option)}
        </Box>)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={getLangSuggetionLabel()}
          inputProps={{ ...params.inputProps }}
        ></TextField>)}
    ></Autocomplete>
  )
}
export default LanguageSelect