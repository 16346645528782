/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { createRoot } from 'react-dom/client'
import axios from 'axios'

import './style.css'
import './style-flex.css'
import './theme-material.css'
// import './theme-glass.css'
import './style-mobile.css'

import UserService from './UserService'
import AppProviders from './providers/AppProviders'
import { BiPortalApp } from './BiPortal'

const CONTENT_TYPE = 'application/json'
const CONTENT_TYPE_HEADER = 'Content-Type'
axios.defaults.headers.post[CONTENT_TYPE_HEADER] = CONTENT_TYPE
axios.defaults.headers.get[CONTENT_TYPE_HEADER] = CONTENT_TYPE
axios.defaults.headers.put[CONTENT_TYPE_HEADER] = CONTENT_TYPE
axios.interceptors.request.use(config =>
  UserService.unauthenticatedPath() ? Promise.resolve(config) :

    new Promise((resolve) => UserService.updateToken(async () => {
      const token = await UserService.getToken()
      if (token && !!config && !!config.headers) config.headers.Authorization = `Bearer ${token}`
      resolve(config)
    }))
)

UserService.initKeycloak(() => {
  const root = createRoot(document.getElementById('app-root')!)
  root.render(
    <React.StrictMode>
      <AppProviders>
        <BiPortalApp />
      </AppProviders>
    </React.StrictMode>)
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
