import React from "react"
import { Check, WarningAmber } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material"

interface Props {
    open: boolean,
    message: string,
    info?: boolean,
    onClose: () => void
}
const ErrorDialog: React.FC<Props> = ({ open, message, onClose, info }) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText className="text-center">
          {info ?
            <Check color="success" /> :
            <WarningAmber color="error" />
          }
          <p>
            {message}
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          Close
        </Button>  
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
