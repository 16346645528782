import axios from 'axios'

import { LogLevel, LogParams, UserActivityLog } from '@models/logger'

export const sendLog = async (level: LogLevel, message: string, path: string, extraData: LogParams) => {
  await axios.post<UserActivityLog>(`/logs/user/activity`, 
    { level, message, path, extraData })
}
  
export const sendUserInfo = async (data: LogParams) => {
  await axios.post<LogParams>(`/logs/user/settings`, data)
}