export const commonMessages = {
  langFrom: "Język źródłowy",
  langTo: "Język docelowy",
  http: {
    error: {
      connectionTimeout: "Przekroczono limit połączenia!",
      serverException: "Nieoczekiwany błąd serwera",
      serverNotAvailable: "Serwer jest niedostępny",
      requestUnauthorized: "Żądanie nie jest uwierzytelnione"
    }
  },
  translate: {
    copy: "Skopiowano!",
    error: "Wystąpił błąd podczas tłumaczenia tekstu",
    detectError: "Wystąpił błąd podczas wykrywania języka",
    notSupported: "Wybrana para językowa nie jest wspierana"
  }
}