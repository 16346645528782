/* eslint-disable no-else-return */
import { RequesterStageDashboardRecord } from "@models/job"
import { acceptableFileTypes } from "src/models/common"

export function mapObjectToFormData(o: object): FormData {
  const form = new FormData()

  for (const [key, value] of Object.entries(o)) {
    if (!!value || value === false) form.append(key, value)
  }

  return form
}

export function generateDueDate(worktime = 3): string {
  const date = new Date()
  date.setUTCDate(date.getUTCDate() + worktime)
  return `${date.getUTCFullYear()}-${formatDate(date.getUTCMonth() + 1)}-${formatDate(date.getUTCDate())} 12:00:00`
}

export function trimTitle(title: string): string {
  //Trim title that can't be wrapped (one-word-title)
  if (!title.includes(" ") && title.length > 25) {
    return title.substring(0, 10) + "..." + title.slice(title.length - 7)
  }
  return title.length > 30 ?
        (title.substring(0, 22) + "..." + title.slice(title.length - 8)) :
        title
}

export function formatDate(value: number) {
  return value < 10 ? "0" + value : value
}
/**
 * Format file size to human readable text
 * 
 * @param size File size in Bytes
 */
export function formatFileSize(size: number): string {
  if (size < 10000) {
    return `${size} B`
  } else if (size < 100000) {
    return `${(size / 1000).toFixed(2)} KB`
  } else {
    return `${(size / 1000000).toFixed(2)} MB`
  }
}

export function isFileTypeSupported({ type }: any): boolean {
  return acceptableFileTypes.includes(type)
}

export function getLastResultFileId(stages: RequesterStageDashboardRecord[]): string | undefined {
  return stages.reverse()
    .find(({ readyFileTypes }) => readyFileTypes.includes('RESULT'))?.id
}