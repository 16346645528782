/* eslint-disable @typescript-eslint/no-namespace */
import { TabContext, TabPanel, TabList } from "@mui/lab"
import { Box, Tab } from "@mui/material"
import React from "react"
import PageContainer from "src/layout/PageContainer"
import { generateTokenForExfluencyInternal as generateToken } from "src/utils/tokenHelper"

const DashboardsInternalPage: React.FC = () => {

  const [value, setValue] = React.useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <PageContainer title="BI (Internal) Overview">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
            <Tab label="IQ Lingua overview" value="1" />
            <Tab label="Language overview" value="2"/>
            <Tab label="Editing distances overview" value="3" />
            <Tab label="Time usage overview" value="4" />
          </TabList>
          <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
            <Tab label="Zendesk" value="8" />
            <Tab label="Operations Dashboard" value="5" />
            <Tab label="Community Dashboard" value="6" />
            <Tab label="Quality Dashboard" value="7" />
          </TabList>
        </Box>
        <TabPanel value="1"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/BI3duplicated/Overview?:origin=card_share_link&:embed=yes&:toolbar=no&:tooltip=no"}></my-component></TabPanel>
        <TabPanel value="2"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/BI3duplicated/LanguageOverview?:origin=card_share_link&:embed=yes&:toolbar=no"}></my-component></TabPanel>
        <TabPanel value="3"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/BI3duplicated/EditingDistances?:origin=card_share_link&:embed=yes&:toolbar=no"}></my-component></TabPanel>
        <TabPanel value="4"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/BI3duplicated/AssignmentDelivery?:origin=card_share_link&:embed=yes&:toolbar=no"}></my-component></TabPanel>
        <TabPanel value="5"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/Exfluencyinternal/OperationsSalesDashboard?:origin=card_share_link&:embed=yes&:toolbar=no"}></my-component></TabPanel>
        <TabPanel value="6"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/Exfluencyinternal/CommunityDashboard?:origin=card_share_link&:embed=yes&:toolbar=no"}></my-component></TabPanel>
        <TabPanel value="7"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/Exfluencyinternal/QualityDashboard?:origin=card_share_link&:embed=yes&:toolbar=no"}></my-component></TabPanel>
        <TabPanel value="8"><my-component token={generateToken()} url={"https://tableau-server.prod.app.exfluency-cloud.com/views/Exfluencyinternal/WordCostPerLC-Dashboard?:origin=card_share_link&:embed=yes&:toolbar=no"}></my-component></TabPanel>
      </TabContext>
    </PageContainer>
  )
}

export default DashboardsInternalPage
