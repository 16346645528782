import { authMessages } from "./auth"
import { commonMessages } from "./common"
import { langMessages } from "./lang"

export const messages = {
  auth: { ...authMessages },
  common: { ...commonMessages },
  lang: { ...langMessages }
}

export default messages