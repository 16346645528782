import React from "react"
import { ArrowForward, MailOutline } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material"

export type SummaryDialogResults = "TRANSLATE_FILE" | "SHOW_FILES";

interface Props {
    open: boolean,
    onClose: (option: SummaryDialogResults) => void
}
const SummaryDialog: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText className="text-center">
          <MailOutline></MailOutline>
          <p>Thank you for submitting the file.</p>
          <p>We will notify you via e-mail when the translated file is ready. The file will appear in My files.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex-column dialog-actions">
        <Button variant="contained" color="primary" onClick={() => onClose("TRANSLATE_FILE")}>
                    Translate another file
        </Button>
        <Button variant="outlined" onClick={() => onClose("SHOW_FILES")}>
                    Go to my files <ArrowForward/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default SummaryDialog

