export const langMessages = {
  langSuggestion: "Suggestion for source language:",
  auto: "Auto-detect",
  pl: "Polish",
  en: "English",
  ru_ua: "Russian (Ukraine)",
  af: "Afrikaans",
  am: "Amharic",
  ar: "Arabic",
  az: "Azeri / Azerbaijani (Latin)",
  be: "Belarusian",
  bg: "Bulgarian",
  bn: "Bengali",
  bs: "Bosnian",
  ca: "Catalan",
  ceb: "Cebuano",
  co: "Corsican",
  cs: "Czech",
  cy: "Welsh",
  da: "Danish",
  de_at: "German (Austria)",
  de_ch: "German (Switzerland)",
  de_de: "German",
  el_cy: "Greek (Cyprus)",
  el_gr: "Greek",
  en_gb: "English (United Kingdom)",
  en_us: "English (United States)",
  eo: "Esperanto",
  es: "Spanish",
  es_419: "Spanish (Latin America)",
  es_mx: "Spanish (Mexico)",
  et: "Estonian",
  eu: "Basque",
  fa: "Farsi",
  fa_af: "Farsi (Afghanistan)",
  fa_ir: "Farsi (Iran)",
  fi: "Finnish",
  fil: "Filipino",
  fj: "Fijian",
  fr_be: "French (Belgium)",
  fr_ca: "French (Canada)",
  fr_ch: "French (Switzerland)",
  fr_fr: "French",
  fy: "Western Frisian",
  ga: "Irish",
  gd: "Scottish Gaelic",
  gl: "Galician",
  gu: "Gujarati",
  ha: "Hausa",
  he: "Hebrew",
  hi: "Hindi",
  hmn: "Hmong",
  hr: "Croatian",
  ht: "Haitian",
  hu: "Hungarian",
  hy: "Armenian",
  id: "Indonesian",
  ig: "Igbo",
  is: "Icelandic",
  it_ch: "Italian (Switzerland)",
  it_it: "Italian",
  ja: "Japanese",
  jv: "Javanese",
  ka: "Georgian",
  kk: "Kazakh",
  kl: "Greenlandic",
  kln_ke: "Kalenjin",
  ko: "Korean",
  ku: "Kurdish",
  ku_arab: "Kurdish (Arabic)",
  ky_kg: "Kyrgyz (Kyrgyzstan)",
  kz: "Kyrgyz",
  la: "Latin",
  lb: "Luxembourgish",
  lo: "Lao",
  lt: "Lithuanian",
  lv: "Latvian",
  mg: "Malagasy",
  mi: "Maori",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian (Cyrillic)",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  my: "Burmese",
  ne: "Nepali",
  nl_be: "Dutch (Belgium)",
  nl_nl: "Dutch (Netherlands)",
  no: "Norwegian",
  ny: "Chewa",
  pa_arab_pk: "Punjabi (Pakistan, Arabic)",
  pa_in: "Punjabi (India)",
  pa_pk: "Punjabi (Pakistan)",
  prs: "Dari",
  ps: "Pashto",
  pt_br: "Portuguese (Brazil)",
  pt_pt: "Portuguese",
  ro_md: "Romanian (Moldova)",
  ro_ro: "Romanian",
  ru_ru: "Russian",
  sd: "Sindhi",
  si: "Sinhala",
  sk: "Slovak",
  sl: "Slovenian",
  sm: "Samoan",
  sn: "Shona",
  so: "Somali",
  sq: "Albanian",
  sr_cyrl: "Serbian (Cyrillic)",
  sr_latn: "Serbian (Latin)",
  st: "Southern Sotho",
  su: "Sundanese",
  sv_fi: "Swedish (Finland)",
  sv_se: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  ta_in: "Tamil (India)",
  te: "Telugu",
  tg: "Tajik",
  tg_cyrl: "Tajik (Cyrillic)",
  th: "Thai",
  tl: "Tagalog",
  tlh: "Klingon",
  to: "Tongan",
  tr: "Turkish",
  uk: "Ukrainian",
  ur: "Urdu",
  uz_cyrl_uz: "Uzbek (Cyrillic)",
  uz_latn_uz: "Uzbek (Latin)",
  vi: "Vietnamese",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  zh_hans: "Chinese (Simplified)",
  zh_hant: "Chinese (Traditional)",
  zu: "Zulu"
}