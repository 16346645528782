import React from "react"

interface Props {
    title: string
    children: JSX.Element | JSX.Element[],
    split?: boolean,
    footer?: JSX.Element
}
const PageContainer = ({ children, title, split, footer }: Props) => {
  return (
    <div className="page-container">
      <h1 className={split ? 'page-title--split': 'page-title'}>
        {title}
      </h1>
      <div className={split ? 'page-content--split' : 'page-content'}>
        {children}
      </div>
      {!!footer && (<div className="page-content--footer">{footer}</div>)}
    </div>
  )
}
export default PageContainer