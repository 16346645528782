/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React from "react"
import { useTranslation } from "react-i18next"

import { Button } from "@mui/material"

import './style.css'
import { formatFileSize, isFileTypeSupported } from "src/utils/fileHelper"
import { acceptableFileTypes } from "src/models/common"

import FileUploadDrop from "./FileUploadDrop"
import FileUploadLabel from "./FileUploadLabel"
import FileUploadItem from "./FileUploadItem"

const MAX_FILE_SIZE = 100 * 1000 * 1000

interface FileUploadProps {
    onFileUploaded: (file: any) => void
    onError: (size: string) => void
}
const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {

  const [file, setFile] = React.useState<any>()
  const [isDragOver, setDragOver] = React.useState<boolean>(false)
  const { t } = useTranslation('common')

  const handleChange = (e: any) => {
    const file = e.target.files[0]
    if (file) { readFile(file) }
  }

  const clearFile = () => {
    setFile(null)
  }

  const renderTitle = () => {
    if (isDragOver) return (<FileUploadDrop />)
    return file ?
            <FileUploadItem file={file} onFileRemoved={clearFile} /> :
            <FileUploadLabel maxFileSize={formatFileSize(MAX_FILE_SIZE)} />
  }

  const onDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const file = e.dataTransfer.files[0]
    if (file) {
      setDragOver(false)
      if (!isFileTypeSupported(file)) return
      readFile(file)
    }
  }

  const onDragEnter = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(true)
  }

  const onDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(false)
  }

  const readFile = (file: any) => {
    if (file.size > MAX_FILE_SIZE) {
      props.onError(formatFileSize(MAX_FILE_SIZE))
      return
    }
    setFile(file)
    props.onFileUploaded(file)
  }

  return (
    <div className="file-upload--container">
      <input
        type="file"
        onChange={handleChange}
        id="file-upload"
        className="none"
        accept={acceptableFileTypes.join(', ')}
      />
      <div className="file-upload--description">
        {renderTitle()}
        <Button variant="outlined" className="file-upload--btn">{file ?
                    t('document.file.replace') :
                    t('document.file.upload')
        }</Button>
      </div>
      <label
        htmlFor="file-upload"
        className={`file-upload--backdrop ${isDragOver && 'file-upload--drop'}`}
        onDrop={onDrop} onDragOver={onDragOver} onDragEnter={onDragEnter} onDragLeave={onDragLeave}
      ></label>

    </div>
  )
}

export default FileUpload
