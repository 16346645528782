import React from "react"
import './ExfluencyLoader.css'

interface Props {
    message: string
}
const ExfluencyLoader: React.FC<Props> = ({ message }) => {
  return (
    <div className="ex-loader">
      <img src="exfluency_loader.svg" alt='Exfluency logo'/>
      <div>{message}</div>
    </div>
  )
}

export default ExfluencyLoader