import { KJUR } from "jsrsasign"
import { v4 } from "uuid"

const EXPIRED_IN = 2
const TOKEN_ALGORITHM = 'HS256'
const TOKEN_AUDIENCE = 'tableau'
const TOKEN_SCOPE = ["tableau:views:embed","tableau:metrics:embed"]

export function generateTokenForExfluency() {
  return generateToken("65dfbecb-d006-47f5-8a1f-adb7370ab59b", "894ed3c3-0d18-4044-81e5-29688b69c7b9", "FhRbZ7qxLlTMZENBgrYMWUjl5PKVeKpGL1MAOVoIlic=")
}

export function generateTokenForExfluencyInternal() {
  return generateToken("0af33a7e-4330-49b0-a3d5-1a9baaaff753", "0a3f0446-497f-484d-a7a4-120592445703", "orPca0HBDydBDFfJrzVd7W9STM/a3LH2vItR5F5H+Bg=")
}

function generateToken(clientId: string, secredId: string, secretVal: string) {
  const exp = Math.floor(new Date(new Date().getTime() + 60000 + EXPIRED_IN).getTime()/1000)
  const jti = v4()
  const sub = "exfluency"
  const iss = clientId
  const kid = secredId

  if(!sub || !iss || !kid || !secretVal) {
    throw(new Error(`Could not generate token. Tableau credentials are missing`))
  }

  const header = { 
    alg: TOKEN_ALGORITHM, 
    typ: 'JWT',
    kid, 
    iss
  }

  const payload = { iss, sub, exp, jti, aud: TOKEN_AUDIENCE, scp: TOKEN_SCOPE }

  return KJUR.jws.JWS.sign(
    TOKEN_ALGORITHM, 
    JSON.stringify(header),
    JSON.stringify(payload),
    secretVal
  )
}